export const getBaseURL = (feature) => {
    switch (feature) {
        case "dashboard":
            return process.env.VUE_APP_METRICS_API_URL;
        case "login":
            return process.env.VUE_APP_DF_API_URL;
        case "media":
            return process.env.VUE_APP_CURATOR_API_URL;
        case "news":
            return process.env.VUE_APP_NEWS_API_URL;
        case "subscription":
            return process.env.VUE_APP_SUBSCRIPTION_API_URL;
        case "stories":
            return process.env.VUE_APP_STORIES_API_URL;
        default:
            return process.env.VUE_APP_INHOUSE_API_URL;
    }
};
