import { v4 as uuidv4 } from "uuid";
import { useToast } from "vue-toastification";
const toast = useToast();
import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";

export default {
  state: {
    usersList: [],
    users: [],
  },
  getters: {
    getUsersList: (state) => state.usersList,
    getUsers: (state) => state.users,
  },
  mutations: {
    GET_USERS_LIST(state, data) {
      state.usersList = data;
    },
    GET_USERS(state, data) {
      state.users = data;
    },
  },
  actions: {
    async fetchUsersList({ commit }) {
      commit('CHANGE_LOADING_STATUS', true);
      
      const feature = "inhouse";
      const url = `/organizations/${StorageUtil.getSessionData('currentOrganization')}/users?perPage=200`;
      const options = {
        headers: {
          'Authorization': `Bearer ${StorageUtil.getSessionData('accessToken')}`,
        },
      };

      try {
        const response = await RequestGateway.get(feature, url, options);
        if (response.data && response.data.data && response.data.data.length) {
          commit('GET_USERS_LIST', response.data.data);
        } else {
          commit('GET_USERS_LIST', []);
        }
        return response.data;
      } catch (error) {
        toast.error(error.message, {
          timeout: 2000,
        });
        return error;
      } finally {
        commit('CHANGE_LOADING_STATUS', false);
      }
    },

    async fetchUser({ commit }, userId) {
      const feature = "inhouse";
      const url = `/organizations/${StorageUtil.getSessionData('currentOrganization')}/users/${userId}`;
      const options = {
        headers: {
          'Authorization': `Bearer ${StorageUtil.getSessionData('accessToken')}`,
        },
      };

      try {
        const response = await RequestGateway.get(feature, url, options);
        return response.data;
      } catch (error) {
        toast.error(error.message, {
          timeout: 2000,
        });
        return error;
      }
    },

    async createUser({ commit }, payload) {
      const feature = "inhouse";
      const url = `/organizations/${StorageUtil.getSessionData('currentOrganization')}/users`;
      const options = {
        headers: {
          'Authorization': `Bearer ${StorageUtil.getSessionData('accessToken')}`,
        },
        data: payload,
      };

      try {
        const response = await RequestGateway.post(feature, url, payload, options);
        return response;
      } catch (error) {
        toast.error(error.message, {
          timeout: 2000,
        });
        return error;
      }
    },

    async updateUser({ commit }, { payload, userId }) {
      const feature = "inhouse";
      const url = `/organizations/${StorageUtil.getSessionData('currentOrganization')}/users/${userId}`;
      const options = {
        headers: {
          'Authorization': `Bearer ${StorageUtil.getSessionData('accessToken')}`,
        },
        data: payload,
      };

      try {
        const response = await RequestGateway.put(feature, url, payload, options);
        return response;
      } catch (error) {
        toast.error(error.message, {
          timeout: 2000,
        });
        return error;
      }
    },

    async deleteUser({ commit }, userId) {
      const feature = "inhouse";
      const url = `/organizations/${StorageUtil.getSessionData('currentOrganization')}/users/${userId}`;
      const options = {
        headers: {
          'Authorization': `Bearer ${StorageUtil.getSessionData('accessToken')}`,
        },
      };

      try {
        const response = await RequestGateway.delete(feature, url, options);
        return response;
      } catch (error) {
        toast.error(error.message, {
          timeout: 2000,
        });
        return error;
      }
    },
  },
};
