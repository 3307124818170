import { useToast } from "vue-toastification";
const toast = useToast();
import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";

export default {
    state: {
        configObjectList: [],
        videoList: [],
        storyList: [],
        videoListBySessionId: [],
        mediaLibraryList: [],
        mediaLibraryTotalRows: 0,
        isVideoBySessionLoading: false,
        currentVideoUrl: "",
    },
    getters: {
        videoList: (state) => state.videoList,
        getConfigObjectList: (state) => state.configObjectList,
        getStoryList: (state) => state.storyList,
        getVideoBySessionId: (state) => state.videoListBySessionId,
        getMediaLibraryList: (state) => state.mediaLibraryList,
        mediaLibraryTotalRows: (state) => state.mediaLibraryTotalRows,
        getCurrentVideoUrl: (state) => state.currentVideoUrl,
    },
    mutations: {
        CONFIG_OBJECT_LIST(state, data) {
            state.configObjectList = data;
        },
        VIDEO_LIST(state, data) {
            state.videoList = data;
        },
        GET_STORY_LIST(state, data) {
            state.storyList = data;
        },
        MEDIA_LIBRARY_LIST(state, data) {
            state.mediaLibraryList = data.mediaData;
            state.mediaLibraryTotalRows = data.totalRow;
        },
        SET_VIDEO_BY_SESSION(state, data) {
            state.videoListBySessionId = data;
        },
        SET_VIDEO_BY_SESSION_LOADER(state, value) {
            state.isVideoBySessionLoading = value;
        },
        CURRENT_VIDEO_URL(state, data) {
            state.currentVideoUrl = data;
        },
    },
    actions: {
        async uploadVideoAnalysis({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessionsWithoutVideo`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            console.log("local payload", data);
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    options
                );
                console.log("response received", response);
                return response.data;
            } catch (error) {
                return error;
            }
        },
        async createVideoStory({ commit }, { payload, onProgress }) {
            const feature = "stories";
            const url = `/processMediaSSE`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
                data: payload,
                onDownloadProgress: function (progressEvent) {
                    const chunk = progressEvent.currentTarget.response;
                    // Call the callback function to handle the chunk in the component
                    if (onProgress) {
                        onProgress(chunk);
                    }
                },
            };

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    options
                );
                return response;
            } catch (error) {
                console.error(error);
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async getVideoStories({ commit }) {
            const feature = "stories";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/getVideoStories?limit=200&perPage=200`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            commit("CHANGE_LOADING_STATUS", true);
            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("CHANGE_LOADING_STATUS", false);
                return response.data;
            } catch (error) {
                commit("CHANGE_LOADING_STATUS", false);
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async getConfigObject1({ commit }) {
            const feature = "media";
            const url = `/objects?perPage=200`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                if (
                    response.data &&
                    response.data.data &&
                    response.data.data.length
                ) {
                    commit("CONFIG_OBJECT_LIST", response.data.data);
                    return response.data;
                } else {
                    commit("CONFIG_OBJECT_LIST", []);
                    return [];
                }
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async downloadVideoDetailsBySession({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${data.sessionId}/videos/${data.videoId}/download`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                return response.data;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async createSession({ commit }, payload) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
                data: payload,
            };

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    options
                );
                if (response.status === 200) {
                    toast.success("Video request sent successfully", {
                        timeout: 4000,
                    });
                } else {
                    if (response && response.data && response.data.error) {
                        let errorMsg =
                            response.data.error.charAt(0).toUpperCase() +
                            response.data.error.substring(1);
                        toast.error(errorMsg, {
                            timeout: 4000,
                        });
                    } else {
                        toast.error(
                            "Session name already in use. Please choose another.",
                            {
                                timeout: 4000,
                            }
                        );
                    }
                }
                return response;
            } catch (error) {
                console.error(error);
                return error;
            }
        },
        async getMediaLibraryNew(
            { commit },
            { page = 1, perPage = 10, search = "" } = {}
        ) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/media?perPage=200`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            commit("CHANGE_LOADING_STATUS", true);
            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                if (
                    response.data &&
                    response.data.data &&
                    response.data.data.length
                ) {
                    commit("MEDIA_LIBRARY_LIST", {
                        mediaData: response.data.data,
                        totalRow: response.data.totalCount,
                    });
                } else {
                    commit("MEDIA_LIBRARY_LIST", {
                        data: [],
                        total: 0,
                        currentPage: 1,
                        totalPages: 1,
                    });
                }
                commit("CHANGE_LOADING_STATUS", false);
                return response.data;
            } catch (error) {
                commit("CHANGE_LOADING_STATUS", false);
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async getPresignedUrl({ commit }, { option, bucketName, mediaName }) {
            try {
                const response = await axios.get(
                    `https://media-bridge-dev.adminagcloud.workers.dev`,
                    {
                        params: {
                            fileName: mediaName,
                            bucketName,
                        },
                    }
                );
                commit("CURRENT_VIDEO_URL", response.data.url);
                return response.data.url;
            } catch (error) {
                console.error(error);
                toast.error(error.message, {
                    timeout: 2000,
                });
            }
        },
        async getVideoDetailsBySession({ commit }) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${
                JSON.parse(localStorage.getItem("currentSessionData")).Id
            }/videos?perPage=200`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            commit("SET_VIDEO_BY_SESSION_LOADER", true);
            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("SET_VIDEO_BY_SESSION_LOADER", false);
                if (
                    response.data &&
                    response.data.data &&
                    response.data.data.length
                ) {
                    commit("SET_VIDEO_BY_SESSION", response.data);
                    return response.data;
                } else {
                    commit("SET_VIDEO_BY_SESSION", []);
                    return [];
                }
            } catch (error) {
                commit("SET_VIDEO_BY_SESSION_LOADER", false);
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async downloadMediaLibrary({ commit }, mediaLibraryId) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/media/${mediaLibraryId}/download`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("CURRENT_VIDEO_URL", response.data.data.url);
                return response.data;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async saveMediaLibrary({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/media`;
            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
                onUploadProgress: (progressEvent) => {
                    data?.onUploadProgress &&
                        data.onUploadProgress(progressEvent);
                },
            };
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    options
                );
                return response.data;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async getNewSessionList({ commit }) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions?perPage=200`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            commit("CHANGE_LOADING_STATUS", true);
            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("CHANGE_LOADING_STATUS", false);
                if (
                    response.data &&
                    response.data.data &&
                    response.data.data.length
                ) {
                    commit("sessionList", response.data.data);
                    return response.data;
                } else {
                    commit("sessionList", []);
                    return [];
                }
            } catch (error) {
                commit("CHANGE_LOADING_STATUS", false);
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async videoDetailsBySessionOutput({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${data.sessionId}/videos/${
                data.videoId
            }/outputs?perPage=200`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            commit("CHANGE_LOADING_STATUS", true);
            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("CHANGE_LOADING_STATUS", false);
                return response.data;
            } catch (error) {
                commit("CHANGE_LOADING_STATUS", false);
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async downloadVideoSessionOutput({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${data.sessionId}/videos/${data.videoId}/outputs/${
                data.outputVideoId
            }/download`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                return response.data;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async deleteMediaLibraryVideo({ commit }, videoId) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/media/${videoId}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };
            try {
                const response = await RequestGateway.delete(
                    feature,
                    url,
                    options
                );
                return response;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
    },
};
