<template>
  <div>
    <router-view />
  </div>
</template>

<script setup>
import { onMounted } from "vue";


import { useToast } from "vue-toastification";
const toast = useToast();
import store from "./store";

onMounted(() => {
  console.log('store data', store);
});


</script>
