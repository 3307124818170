import { v4 as uuidv4 } from "uuid";
import { useToast } from "vue-toastification";
const toast = useToast();
import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";

export default {
    state: {
        newsEventList: [],
        WorldStories: [],
        filterData: [],
    },
    getters: {
        getNewsEvents: (state) => state.newsEventList,
        getWorldStories: (state) => state.WorldStories,
        getFilterData: (state) => state.filterData,
    },
    mutations: {
        GET_WORLD_STORIES(state, data) {
            state.WorldStories = data;
        },
        NEWS_EVENT_LIST(state, data) {
            state.newsEventList = data;
        },
        SET_FILTER_DATA(state, data) {
            state.filterData = data;
        },
    },
    actions: {
        async fetchClientNews({ commit }) {
            const feature = "news";
            const url = `clientnews?MainClientId=${StorageUtil.getSessionData(
                "currentOrganization"
            )}&EndpointType=q&MaximumArticles=10&specialt=8W1ZzUj-1xpYlUgI1BwgU`;
            const options = {
                Authorization: `Bearer ${StorageUtil.getSessionData(
                    "accessToken"
                )}`,
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );

                commit("NEWS_EVENT_LIST", response.data);
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
            } finally {
                commit("CHANGE_LOADING_STATUS", false); // Assuming you have a loading status mutation
            }
        },
        async fetchWorldStories({ commit }) {
            const feature = "news";
            const url = `worldnews?specialt=8W1ZzUj-1xpYlUgI1BwgU`;
            const options = {
                Authorization: `Bearer ${StorageUtil.getSessionData(
                    "accessToken"
                )}`,
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                console.log('response of world news', response);
                commit("GET_WORLD_STORIES", response.data);
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
            } finally {
                commit("CHANGE_LOADING_STATUS", false); // Assuming you have a loading status mutation
            }
        },
        async filterNews({ commit }, data) {
            const feature = "news";
            const url = `createFilter?MainClientId=${StorageUtil.getSessionData(
                "currentOrganization"
            )}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;
            const options = {
                Authorization: `Bearer ${StorageUtil.getSessionData(
                    "accessToken"
                )}`,
            };
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    options
                );
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
            } finally {
                commit("CHANGE_LOADING_STATUS", false); // Assuming you have a loading status mutation
            }
        },
        async getFilterData({ commit }) {
            const feature = "news";
            const url = `newsFilters?MainClientId=${StorageUtil.getSessionData(
                "currentOrganization"
            )}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;
            const options = {
                Authorization: `Bearer ${StorageUtil.getSessionData(
                    "accessToken"
                )}`,
            };
            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("SET_FILTER_DATA", response.data);
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
            } finally {
                commit("CHANGE_LOADING_STATUS", false); // Assuming you have a loading status mutation
            }
        },
        async deleteFilter({ commit }, recordId) {
            const feature = "news";
            const url = `deleteFilter?MainClientId=${StorageUtil.getSessionData(
                "currentOrganization"
            )}&RecordTypeId=${recordId}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };
            console.log(`deleting payload`, recordId);
            try {
                const response = await RequestGateway.delete(
                    feature,
                    url,
                    options
                );
                return response;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async updateFilters({ commit }, data) {
            const feature = "news";
            const url = `createFilter?MainClientId=${StorageUtil.getSessionData(
                "currentOrganization"
            )}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;
            const options = {
                Authorization: `Bearer ${StorageUtil.getSessionData(
                    "accessToken"
                )}`,
            };
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    options
                );
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
            } finally {
                commit("CHANGE_LOADING_STATUS", false); // Assuming you have a loading status mutation
            }
        },
    },
};
