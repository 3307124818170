import axios from "axios";
import { getBaseURL } from "@/constant/requestHeader";

export class RequestGateway {
    static get = (feature, url, options = {}) => {
        return requestGateway(feature, "get", url, options);
    };

    static post = (feature, url, body, options = {}) => {
        const updateOptions = { ...options, data: body };
        return requestGateway(feature, "post", url, updateOptions);
    };

    static put = (feature, url, body, options = {}) => {
        const updateOptions = { ...options, data: body };
        return requestGateway(feature, "put", url, updateOptions);
    };

    static delete = (feature, url, options = {}) => {
        const updateOptions = { ...options };
        return requestGateway(feature, "delete", url, updateOptions);
    };
}

const requestGateway = (feature, method, url, options) => {
    return axios.request({
        method: method,
        url: url,
        ...options,
        responseType: "json", // This can be set to "stream" if the API supports it.
        baseURL: getBaseURL(feature),
        headers: {
            ...options.headers,
        },
        onDownloadProgress: options.onDownloadProgress, // Include the progress handler
    });
};
