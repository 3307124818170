import "animate.css";
import "flatpickr/dist/flatpickr.css";
import "simplebar-vue/dist/simplebar-vue.js";
import "simplebar/dist/simplebar.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import { createApp } from "vue";
import VueFlatPickr from "vue-flatpickr-component";
import VueGoodTablePlugin from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import VueSweetalert2 from "vue-sweetalert2";
import VueTippy from "vue-tippy";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueApexCharts from "vue3-apexcharts";
import VueClickAway from "vue3-click-away";
import App from "./App.vue";
import "./assets/scss/auth.scss";
import "./assets/scss/tailwind.scss";
import router from "./router";
import store from "./store";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import VueLazyload from "vue-lazyload";
import StorageUtil from "./utils/StorageUtil";

// check localStorage theme for dark light bordered
if (localStorage.theme === undefined) {
    localStorage.setItem("theme", "dark");
}
if (localStorage.theme === "dark") {
    document.body.classList.add("dark");
    store.state.theme = "dark";
    store.state.isDark = true;
} else {
    document.body.classList.add("light");
    store.state.theme = "light";
    store.state.isDark = false;
}
if (localStorage.semiDark === "true") {
    document.body.classList.add("semi-dark");
    store.state.semidark = true;
    store.state.semiDarkTheme = "semi-dark";
} else {
    document.body.classList.add("semi-light");
    store.state.semidark = false;
    store.state.semiDarkTheme = "semi-light";
}
// check loacl storege for menuLayout
if (localStorage.menuLayout === "horizontal") {
    store.state.menuLayout = "horizontal";
} else {
    store.state.menuLayout = "vertical";
}

// check skin  for localstorage
if (localStorage.skin === "bordered") {
    store.state.skin = "bordered";
    document.body.classList.add("skin--bordered");
} else {
    store.state.skin = "default";
    document.body.classList.add("skin--default");
}

function loadGoogleMapsApi(apiKey) {
    return new Promise((resolve, reject) => {
        if (
            typeof window.google === "object" &&
            typeof window.google.maps === "object"
        ) {
            resolve();
            return;
        }

        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;

        script.onload = () => {
            resolve();
        };

        script.onerror = (error) => {
            reject(error);
        };

        document.head.appendChild(script);
    });
}

// Load the Google Maps API with your API key
loadGoogleMapsApi(process.env.VUE_APP_GOOGLE_MAPS_PLACE_KEY)
    .then(() => {
        // vue use
        const app = createApp(App);

        app.use(store)
            .use(VueSweetalert2)
            .use(Toast, {
                toastClassName: "dashcode-toast",
                bodyClassName: "dashcode-toast-body",
            })
            .use(VueLazyload, {
                preLoad: 1.3,
                // error: 'path/to/error.png',  // Path to error image
                // loading: 'path/to/loading.gif',  // Path to loading image
                attempt: 1,
            })
            .use(router)
            .use(VueClickAway)
            .use(VueTippy)
            .use(VueFlatPickr)
            .use(VueGoodTablePlugin)
            .use(VueApexCharts)
            .use(VCalendar)
            .use(VueTelInput)
            .mount("#app");

        console.log("Google Maps API loaded successfully.");
    })
    .catch((error) => {
        console.error("Error loading Google Maps API:", error);
    });
