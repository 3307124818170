import { useToast } from "vue-toastification";
const toast = useToast();
import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";

export default {
    state: {
        isSessionCreateFail: false,
        currentSessionId: null,
    },
    getters: {
        getIsSessionCreateFail: (state) => state.isSessionCreateFail,
        getCurrentSessionId: (state) => state.currentSessionId,
    },
    mutations: {
        IS_SESSION_CREATE_FAIL(state, val) {
            state.isSessionCreateFail = val;
        },
        UPDATE_CURRENT_SESSION_ID(state, id) {
            state.currentSessionId = id;
        },
    },
    actions: {
        async getAnalysisReport({ commit }, reportId) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${reportId}/sessionReport`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                return response.data;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
    },
};
